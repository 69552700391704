body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: white;

}

.App {
  /* max-width: 480px; */
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input[type="number"] {
  padding: 5px;
  font-size: 16px;
  width: 100%;
}

button {
  padding: 10px;
  font-size: 16px;
  /* background-color: #007bff; */
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.average-value {
  font-size: 0.8rem;
  vertical-align: middle;
}

.Modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  padding: 20px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 10px 0;
}

.Menu-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.score-end-button {
  position: fixed;
  bottom: 0;
  left:0;
}

.content {
  padding-bottom: 75px;
}